import { createStore } from 'vuex'

const base_url = process.env.VUE_APP_BACKEND_URL

const headers = { 'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('access_token')), 'Access-Control-Allow-Origin': '*' };

export default createStore({
  state: {
    vouchers: [],
    users: [],
    projects: [],
    budgets: [],
    cancellationreasons: [],
    departments: [],
    categories: [],
    approvals: [],
  },
  mutations: { //synchrous
    setVouchers (state, payload){
      state.vouchers = payload
    },
    setUsers (state, payload){
      state.users = payload
    },
    setProjects (state, payload){
      state.projects = payload
    },
    setBudgets (state, payload){
      state.budgets = payload
    },
    setCancellationreasons (state, payload){
      state.cancellationreasons = payload
    },
    setDepartments (state, payload){
      state.departments = payload
    },
    setCategories (state, payload){
      state.categories = payload
    },
    setApprovals (state, payload){
      state.approvals = payload
    },
  },

  actions: { //asynchrous
    async fetchVouchers (state) {
      const res = await fetch(base_url+'/vouchers/',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setVouchers",data)
    },
    async fetchUsers (state) {
      const res = await fetch(base_url+'/users/',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setUsers",data)
    },
    async fetchProjects (state) {
      const res = await fetch(base_url+'/stc/projects/',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setProjects",data.results)
    },
    async fetchBudgets (state) {
      const res = await fetch(base_url+'/stc/budgets/',{headers})
      const data = await res.json()
      state.commit("setBudgets",data.results)
    },
    async fetchCancellationreasons (state) {
      const res = await fetch(base_url+'/stc/cancellation-reasons/',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setCancellationreasons",data.results)
    },
    async fetchDepartments (state) {
      const res = await fetch(base_url+'/stc/departments/',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setDepartments",data.results)
    },
    async fetchCategories (state) {
      const res = await fetch(base_url+'/stc/categories/',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setCategories",data.results)
    },
    async fetchApprovals (state) {
      const res = await fetch(base_url+'/stc/approvals/',{ mode: 'cors', headers})
      const data = await res.json()
      state.commit("setApprovals",data.results)
    },
  },
  modules: {
  },
  getters: {
    getVouchers : state => state.vouchers,
    getUsers : state => state.users,
    getProjects : state => state.projects,
    getBudgets : state => state.budgets,
    getCancellationreasons : state => state.cancellationreasons,
    getDepartments : state => state.departments,
    getCategories : state => state.categories,
    getApprovals : state => state.approvals,
  }
})
