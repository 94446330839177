<template>
    <div class="home">
  
      <Header :mainHeader="true"/>
  
      <section id="warehouse-inventory">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div class="enterprise-one">
                <h3>Pavicon (K) Limited</h3>
                <a href="#" class="btn btn-default" style="border: 1px solid #000;">Change</a>
              </div>
              <h3>Petty Cash Overview</h3>
            </div>
            <div class="col-md-6">
              <div class="quick-actions">
              </div>
            </div>
          </div>
        </div>
      </section>
  
    </div>
  </template>
  
  <script>
  
  import Header from '@/components/Header';
  
  
  
  export default {
    name: 'HomePage',
    components: {
      Header
    },
    data(){
      return{
        date_now: new Date().getDate()+"/"+(new Date().getUTCMonth() + 1)+"/"+new Date().getFullYear()+"  "+new Date().getHours()+":"+new Date().getMinutes(),
        url: process.env.BACKEND_URL
      }
    },
  }
  </script>