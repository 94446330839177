<template>
    <div class="transaction">
      <Header :mainHeader="true"/>
      <section id="transaction-products">
        <div class="container">
          <h3 style="margin-bottom: 0px !important;">Approval Log</h3>
          <div class="warehouse-inventory-card-wrapper" style="margin: 5px 0px;">
            <div class="warehouse-inventory-card">
              <p>Search Total Amount</p>
              <strong class="blue">KES 0.00</strong>
            </div>
          </div>
  
          <div class="filter-search">
            <div class="filter-search-header" style="margin-bottom: 0px;">
              <strong>Search</strong>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date</label>
                      <select class="full" id="handled-by">
                        <option value="all">Date from - Date to</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">P/C No.</label>
                      <input type="text" class="form-control">
                    </div>
                  </div>
                  
                  <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <label for="handled-by">Requested by:</label>
                      <select class="full" id="handled-by">
                        <option value="all">All</option>
                      </select>
                    </div>
                  </div>
                  
                  <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <label for="handled-by">Priority Level</label>
                      <select class="full" id="handled-by">
                        <option value="all">All</option>
                      </select>
                    </div>
                  </div>
                  
                  <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <label for="handled-by">Project/Dept</label>
                      <select class="full" id="handled-by">
                        <option value="all">All</option>
                      </select>
                    </div>
                  </div>
                  
                  <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <label for="handled-by">Budget Line</label>
                      <select class="full" id="handled-by">
                        <option value="all">All</option>
                      </select>
                    </div>
                  </div>
                  
                  <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <label for="handled-by">P/C Status</label>
                      <select class="full" id="handled-by">
                        <option value="all">All</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <label for="handled-by">Payment Status</label>
                      <select class="full" id="handled-by">
                        <option value="all">All</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <a href="#" class="btn-style small">Search</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="vouchers-list">
            <table id="vouchersTable" class="table table-hover">
                <thead>
                    <th>No.</th>
                    <th>P/C No.</th>
                    <th>Status</th>
                    <th>A/Runtime</th>
                    <th>Request Date</th>
                    <th>Request By</th>
                    <th>Project/Department</th>
                    <th>Budget Line</th>
                    <th>Amount Requested</th>
                    <th>Payment Status</th>
                    <th>Request Description</th>
                    <th>Action</th>
                </thead>
                <tbody v-if="vouchers.length > 0">
                  <tr v-for="(voucher,index) in vouchers" v-bind:key="voucher.id">
                    <td>{{ index++ }}</td>
                    <td>{{ voucher.id }}</td>
                    <td>{{ voucher.voucher.status }}</td>
                    <td>-</td>
                    <td>{{ formatDate(voucher.voucher.created_at,true) }}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{{ voucher.voucher.budget_line.name }}</td>
                    <td>KES {{ voucher.voucher.amount }}</td>
                    <td>{{ voucher.voucher.payment_status }}</td>
                    <td>{{ voucher.voucher.description }}</td>
                    <td><button class="btn btn-primary btn-sm">Action</button></td>
                  </tr>
                </tbody>
                <div v-else style="position: absolute; left: 0; right: 0; margin-top: 10px;">
                  <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No request found</label>
                </div>
          </table>
          </div>
          
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import Header from '../../components/Header';
  
  export default {
    name: 'ApprovalLog',
    components: {
      Header,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        urlServer:process.env.VUE_APP_BACKEND_URL,
        vouchers: [],
    }),
    mounted(){
        this.getVouchers()
    },
    methods:{
      async getVouchers () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_BACKEND_URL+'/stc/approvals/', requestOptions)
        const result = await res.json()
        this.vouchers = result.results
      },
    }
  }
  </script>

  <style scoped>
  .vouchers-list{
    padding: 0px;
    margin-top: 30px;
    /* box-shadow: rgba(49, 32, 104, 0.2) 0 2px 8px 0; */
  }
  </style>