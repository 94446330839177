<template>
    <div class="transaction">
      <Header :mainHeader="true"/>
      <section id="transaction-products">
        <div class="container">
          <h3 style="margin-bottom: 10px !important;">Configurations</h3>
  
          <div class="row">
            <div class="col-md-2">
                <div><p style="background-color: #dae3f3; color: #0070c1; padding: 10px 20px; border-radius: 5px; cursor: pointer;">General</p></div>
                <div><p style="padding: 10px 20px;cursor: pointer;">Errors Code</p></div>
            </div>
            <div class="col-md-10">
                <div class="row">
                    <div class="col-md-2">
                        <div><p @click="toggleDisplay('budget')" :style="'background-color:'+budgetP+';padding:10px; margin-bottom: 0px !important;cursor: pointer;'">Budget Lines</p></div>
                        <div><p @click="toggleDisplay('department')" :style="'background-color:'+departmentP+';padding:10px; margin-bottom: 0px !important;cursor: pointer;'">Departments</p></div>
                        <div><p @click="toggleDisplay('cancel')" :style="'background-color:'+cancelP+';padding:10px; margin-bottom: 0px !important;cursor: pointer;'">P/C Cancellation Reasons</p></div>
                    </div>
                    <div class="col-md-10">
                        <div :style='"display:"+displayBudget+";"'>
                            <h3>Budget lines</h3>
                                <div class="form-group col-md-3" style="float: left;">
                                    <label for="address">Budget Line ID</label>
                                    <input type="text" v-model="budgetLine" placeholder="" class="form-control">
                                </div>
                                <div class="form-group col-md-3" style="float: left;margin-left: 20px;">
                                    <label for="address">Budget Line Name</label>
                                    <input type="text" v-model="budgetName" placeholder="" class="form-control">
                                </div>
                                <div class="form-group col-md-1" style="float: left; margin-left: 20px; margin-top: 25px;">
                                    <button class="btn-style full" type="button" @click="postBudgetLine">Add</button>
                                </div>
                            <div style="clear: all; width: 100%; float: left;">
                                <table class="table table-hover">
                                    <thead>
                                        <th>No.</th>
                                        <th>ID</th>
                                        <th>Budget Line</th>
                                        <th>Action</th>
                                    </thead>
                                    <tbody v-if="budgets.length > 0">
                                        <tr v-for="(budget,index) in budgets" v-bind:key="budget.id">
                                            <td>{{ (index + 1) }}</td>
                                            <td>{{ budget.line_id }}</td>
                                            <td>{{ budget.name }}</td>
                                            <td><button class="btn-style">Delete</button><button class="btn-style">Manage Categories</button></td>
                                        </tr>
                                    </tbody>
                                    <div v-else style="position: absolute; left: 0; right: 0; margin-top: 10px;">
                                        <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No budget line found</label>
                                    </div>
                                </table>
                            </div>
                        </div>
                        
                        <div :style='"display:"+displayDepartment+";"'>
                            <h3>Departments</h3>
                                <div class="form-group col-md-4" style="float: left;">
                                    <label for="address">Department</label>
                                    <input type="text" v-model="departmentName" placeholder="" class="form-control">
                                </div>
                                <div class="form-group col-md-1" style="float: left; margin-left: 20px; margin-top: 25px;">
                                    <button class="btn-style full" type="button" @click="postDepartment">Add</button>
                                </div>
                            <div style="clear: all; width: 100%; float: left;">
                                <table class="table table-hover">
                                    <thead>
                                        <th>No.</th>
                                        <th>Value</th>
                                        <th>Action</th>
                                    </thead>
                                    <tbody v-if="departments.length > 0">
                                        <tr v-for="(department,index) in departments" v-bind:key="department.id">
                                            <td>{{ (index + 1) }}</td>
                                            <td>{{ department.name }}</td>
                                            <td><button class="btn-style">Delete</button></td>
                                        </tr>
                                    </tbody>
                                    <div v-else style="position: absolute; left: 0; right: 0; margin-top: 10px;">
                                        <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No department found</label>
                                    </div>
                                </table>
                            </div>
                        </div>
                        
                        <div :style='"display:"+displayCancel+";"'>
                            <h3>Petty Cash Cancellation Reasons</h3>
                                <div class="form-group col-md-4" style="float: left;">
                                    <label for="address">Value</label>
                                    <input type="text" v-model="cancelName" placeholder="" class="form-control">
                                </div>
                                <div class="form-group col-md-1" style="float: left; margin-left: 20px; margin-top: 25px;">
                                    <button class="btn-style full" type="button" @click="postCancel">Add</button>
                                </div>
                            <div style="clear: all; width: 100%; float: left;">
                                <table class="table table-hover">
                                    <thead>
                                        <th>No.</th>
                                        <th>Value</th>
                                        <th>Action</th>
                                    </thead>
                                    <tbody v-if="cancels.length > 0">
                                        <tr v-for="(cancel,index) in cancels" v-bind:key="cancel.id">
                                            <td>{{ (index + 1) }}</td>
                                            <td>{{ cancel.name }}</td>
                                            <td><button class="btn-style">Delete</button></td>
                                        </tr>
                                    </tbody>
                                    <div v-else style="position: absolute; left: 0; right: 0; margin-top: 10px;">
                                        <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No cancellation reason found</label>
                                    </div>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </section>
  
    </div>
  </template>
  
  <script>
  
  import Header from '../../components/Header';
  import Swal from 'sweetalert2'

  export default {
    name: 'ConfigurationsPage',
    components: {
      Header,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        vouchers: [],
        budgetName:"",
        budgetLine:"",
        departmentName: "",
        cancelName: "",
        displayDepartment: 'none',
        displayBudget: 'block',
        displayCancel: 'none',
        budgetP: "#fff",
        departmentP: "#fff",
        cancelP: "#fff",
        budgets: [],
        categories: [],
        departments: [],
        cancels: [],
    }),
    mounted(){
      this.getBudgets()
      this.getCategories()
      this.getCancels()
      this.getDepartments()
    },
    methods:{
      toggleDisplay(section) {
        if (section === 'budget') {
            this.displayBudget = "block";
            this.displayCancel = "none";
            this.displayDepartment = "none";
            this.budgetP = "#dae3f3"
            this.departmentP = "#fff"
            this.cancelP = "#fff"
        } else if (section === 'department') {
            this.displayBudget = "none";
            this.displayCancel = "none";
            this.displayDepartment = "block";
            this.cancelP = "#fff"
            this.budgetP = "#fff"
            this.departmentP = "#dae3f3"
        }else if (section === 'cancel') {
            this.displayBudget = "none";
            this.displayDepartment= "none";
            this.displayCancel = "block";
            this.budgetP = "#fff"
            this.departmentP = "#fff"
            this.cancelP = "#dae3f3"
        }
      },
      async postBudgetLine () {
        if(this.budgetName != "" && this.budgetLine != ""){
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    line_id: this.budgetLine,
                    name: this.budgetName
                })
            };
            const res = await fetch(process.env.VUE_APP_BACKEND_URL+'/stc/budgets/', requestOptions)
            const result = await res.json()
            console.log(result)
            Swal.fire({
                icon: 'success',
                title: 'Budget line created successfully',
                text: ""
            })
            this.budgetLine = ""
            this.budgetName = ""
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Missing Field or Data',
                text: "Kindly fill all the fields provided"
            })
        }
      },
      async postDepartment () {
        if(this.departmentName != ""){
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    name: this.departmentName
                })
            };
            const res = await fetch(process.env.VUE_APP_BACKEND_URL+'/stc/departments/', requestOptions)
            const result = await res.json()
            console.log(result)
            Swal.fire({
                icon: 'success',
                title: 'Department created successfully',
                text: ""
            })
            this.departmentName = ""
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Missing Field or Data',
                text: "Kindly fill all the fields provided"
            })
            return false;
        }
      },
      async postCancel () {
        if(this.cancelName != ""){
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                    name: this.cancelName
                })
            };
            const res = await fetch(process.env.VUE_APP_BACKEND_URL+'/stc/cancellation-reasons/', requestOptions)
            const result = await res.json()
            console.log(result)
            Swal.fire({
                icon: 'success',
                title: 'Cancellation Reason created successfully',
                text: ""
            })
            this.cancelName = ""
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Missing Field or Data',
                text: "Kindly fill all the fields provided"
            })
            return false;
        }
      },
      async getBudgets () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_BACKEND_URL+'/stc/budgets/', requestOptions)
        const result = await res.json()
        this.budgets = result.results
      },
      async getDepartments () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_BACKEND_URL+'/stc/departments/', requestOptions)
        const result = await res.json()
        this.departments = result.results
      },
      async getCategories () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_BACKEND_URL+'/stc/categories/', requestOptions)
        const result = await res.json()
        this.categories = result.results
      },
      async getCancels () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_BACKEND_URL+'/stc/cancellation-reasons/', requestOptions)
        const result = await res.json()
        this.cancels = result.results
      },
    }
  }
  </script>

  <style scoped>
  .vouchers-list{
    padding: 0px;
    margin-top: 30px;
    /* box-shadow: rgba(49, 32, 104, 0.2) 0 2px 8px 0; */
  }
  </style>