import { createWebHistory, createRouter } from "vue-router";
import Login from "@/views/auth/LoginPage";
import Home from "@/views/home/OverviewPage.vue";
import AddRequest from "@/views/requests/AddRequest.vue";
import RequestLog from "@/views/requests/RequestLog.vue";
import ManageRequests from "@/views/approvals/ManageRequests.vue";
import ApprovalLog from "@/views/approvals/ApprovalLog.vue";
import ConfigurationsPage from "@/views/settings/ConfigurationsPage.vue";

const routes = [
    {
        path: "/",
        name: "Login",
        component: Login,
        meta: {
            title: 'Pavicon Petty Cash | Login'
        }
    },
    {
        path: "/home",
        name: "Home",
        component: Home,
        meta: {
            title: 'Overview | Pavicon Petty Cash'
        }
    },
    {
        path: "/requests/add",
        name: "AddRequest",
        component: AddRequest,
        meta: {
            title: 'Add Request | Pavicon Petty Cash'
        }
    },
    {
        path: "/requests",
        name: "RequestLog",
        component: RequestLog,
        meta: {
            title: 'Request Log | Pavicon Petty Cash'
        }
    },
    {
        path: "/approvals",
        name: "ManageRequests",
        component: ManageRequests,
        meta: {
            title: 'Manage Requests | Pavicon Petty Cash'
        }
    },
    {
        path: "/approvals/log",
        name: "ApprovalLog",
        component: ApprovalLog,
        meta: {
            title: 'Approval Log | Pavicon Petty Cash'
        }
    },
    {
        path: "/settings/configurations",
        name: "ConfigurationsPage",
        component: ConfigurationsPage,
        meta: {
            title: 'Configurations Settings | Pavicon Petty Cash'
        }
    },
    

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((toRoute, fromRoute, next) => {
    window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Home';
    next();
})

export default router;