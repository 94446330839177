<template>
  <header id="header">
    <div class="container">
      <div class="row" v-if="loginHeader === true">
        <div class="col-md-6 align-self-center">
          <a href="#" class="logo">
            <img src="../assets/images/enterpriseOne_new.png" alt="" style="width: 270px !important;"> 
          </a>
        </div>
        <div class="col-md-6 align-self-center">
          <div class="select-languages">
           <div class="form">
             <select style="border-color: #fff !important;">
               <option value="English">English</option>
               <option value="Chines">Chinese</option>
               <option value="French">French</option>
             </select>
           </div>
            <div class="img-wrapper">
              <i class="fa-solid fa-circle-question"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="row main-header" v-if="mainHeader === true">
        <div class="col-md-6 align-self-end">
          <RouterLink to="home" class="logo">
            <img src="../assets/images/ppt/pavicon_final_logo.png" alt="" style="width: 135px !important;">
          </RouterLink>
        </div>
        
        <div class="col-md-6 align-self-end">
        </div>
        <div class="col-md-7 align-self-end" style="margin-top: 10px;">
          
          <ul class="menu">
            <li><RouterLink to="/home">Home</RouterLink></li>
            <li>
              <RouterLink to="#" @click="menuDropdown3">Requests</RouterLink>
              <ul class="menu menu-dropdown" :style="menuDropdownStyle3" >
                <li><RouterLink to="/requests/add">Add Request</RouterLink></li>
                <li><RouterLink to="/requests">Request Log</RouterLink></li>
              </ul>
            </li>
            <li>
              <RouterLink to="#" @click="menuDropdown2">Approvals</RouterLink>
              <ul class="menu menu-dropdown" :style="menuDropdownStyle2" >
                <li><RouterLink to="/approvals">Manage Requests</RouterLink></li>
                <li class="mt-1"><RouterLink to="/approvals/log">Approval Log</RouterLink></li>
              </ul>
            </li>
            <li>
              <RouterLink to="#" @click="menuDropdown4">P/C Management</RouterLink>
              <ul class="menu menu-dropdown" :style="menuDropdownStyle4" >
                <li><RouterLink to="/">Cash Disbursement</RouterLink></li>
                <li class="mt-1"><RouterLink to="/">Disbursement Log </RouterLink></li>
                <li class="mt-1"><RouterLink to="/">A/C Management </RouterLink></li>
                <li class="mt-1"><RouterLink to="/">Debit Request Log </RouterLink></li>
                <li class="mt-1"><RouterLink to="/">Budget </RouterLink></li>
              </ul>
            </li>
            <li><RouterLink to="/">Bank</RouterLink></li>
            <li>
              <RouterLink to="#" @click="menuDropdown5">Reports</RouterLink>
              <ul class="menu menu-dropdown" :style="menuDropdownStyle5" >
                <li><RouterLink to="/">P/C Analysis</RouterLink></li>
                <li class="mt-1"><RouterLink to="/">Budget Line Analysis</RouterLink></li>
                <li class="mt-1"><RouterLink to="/">Errors/Exception</RouterLink></li>
                <li class="mt-1"><RouterLink to="/">Rank</RouterLink></li>
              </ul>
            </li>
            <li>
              <RouterLink to="#" @click="menuDropdown1">Settings</RouterLink>
              <ul class="menu menu-dropdown" :style="menuDropdownStyle1" >
                <li><RouterLink to="/settings/configurations">Configurations</RouterLink></li>
                <li class="mt-1"><RouterLink to="/">Approval Workflow</RouterLink></li>
                <li class="mt-1"><RouterLink to="/">Notifications</RouterLink></li>
                <li class="mt-1"><RouterLink to="/">Permissions</RouterLink></li>
                <li class="mt-1"><RouterLink to="/">User Log</RouterLink></li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="col-md-2 align-self-end">
          <div class="search-form">
            <input style="width: 180px !important;" type="text" placeholder="Search...">
            <input style="width: 180px !important;" type="submit" id="HeaderSearch">
            <label for="HeaderSearch">
              <i class="fas fa-search"></i>
            </label>
          </div>
        </div>
        <div class="col-md-3 align-self-end">
          <ul class="info">
            <li>
              <div class="form">
                <select v-model="userOption" style="border-color: #fff !important;">
                  <option value="name">User Name</option>
                  <option value="logout">Log Out</option>
                </select>
              </div>
            </li>
            <li  @click="$router.push('/settings/approvals')"> <span class="counter"  style="cursor: pointer;">  0</span> </li>
            <li>
              <div class="bell-icon" style="cursor: pointer;">
                <img src="../assets/images/icons/bell1.png" style="width: 27px !important; height: auto !important;" alt="">
              </div>
            </li>
            <li>
              <div class="question-mark" style="cursor: pointer;">
                <img src="../assets/images/icons/question-mark-circle.png" style="width: 25px !important; height: auto !important;" alt="">
              </div>
            </li>
          </ul></div>
      </div>
    </div>
  </header>
</template>

<script>

export default {
  name: 'FooterComponent',
  props: ['loginHeader', 'mainHeader'],
  data: () => ({
    token: JSON.parse(localStorage.getItem('access_token')),
    user: "",
    userOption:"name",
    menuDropdownShow1: false,
    menuDropdownShow2: false,
    menuDropdownShow3: false,
    menuDropdownShow4: false,
    menuDropdownStyle1: { display: 'none'},
    menuDropdownStyle2: { display: 'none'},
    menuDropdownStyle3: { display: 'none'},
    menuDropdownStyle4: { display: 'none'},
    menuDropdownStyle5: { display: 'none'},
    menuDropdownStyle6: { display: 'none'},
    menuDropData:{
      display: 'grid',
      background: '#fff', 
      position: 'absolute',
      'list-style': 'grid',
      padding: '15px',
      'margin-left': '-15px',
      'border-radius': '5px',
      'border':'1px solid #d2d2d2',
      opacity: '1',
      'z-index': 2,
    },
  }),
  methods: {
    menuDropdown1 () { 
      this.resetDropdowns()
      this.menuDropdownShow1 = !this.menuDropdownShow1;
      if(this.menuDropdownShow1) { this.menuDropdownStyle1 = this.menuDropData } else { this.menuDropdownStyle1 = { display: 'none' }}
    },
    menuDropdown2 () { 
      this.resetDropdowns()
      this.menuDropdownShow2 = !this.menuDropdownShow2;
      if(this.menuDropdownShow2) { this.menuDropdownStyle2 = this.menuDropData } else { this.menuDropdownStyle2 = { display: 'none' }}
    },
    menuDropdown3 () { 
      this.resetDropdowns()
      this.menuDropdownShow3 = !this.menuDropdownShow3;
      if(this.menuDropdownShow3) { this.menuDropdownStyle3 = this.menuDropData } else { this.menuDropdownStyle3 = { display: 'none' }}
    },
    menuDropdown4 () { 
      this.resetDropdowns()
      this.menuDropdownShow4 = !this.menuDropdownShow4;
      if(this.menuDropdownShow4) { this.menuDropdownStyle4 = this.menuDropData } else { this.menuDropdownStyle4 = { display: 'none' }}
    },
    menuDropdown5 () { 
      this.resetDropdowns()
      this.menuDropdownShow5 = !this.menuDropdownShow5;
      if(this.menuDropdownShow5) { this.menuDropdownStyle5 = this.menuDropData } else { this.menuDropdownStyle5 = { display: 'none' }}
    },
    menuDropdown6 () { 
      this.resetDropdowns()
      this.menuDropdownShow6 = !this.menuDropdownShow6;
      if(this.menuDropdownShow6) { this.menuDropdownStyle6 = this.menuDropData } else { this.menuDropdownStyle6 = { display: 'none' }}
    },
    resetDropdowns(){
      this.menuDropdownStyle1 = { display: 'none'}
      this.menuDropdownStyle2 = { display: 'none'}
      this.menuDropdownStyle3 = { display: 'none'}
      this.menuDropdownStyle4 = { display: 'none'}
      this.menuDropdownStyle5 = { display: 'none'}
      this.menuDropdownStyle6 = { display: 'none'}
    },

    async setupUser(){
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.BACKEND_URL+'/users/profile', requestOptions)
        const data = await res.json()
        if(data.status == true){
            this.user = data.user;
            console.log(data.user.Permission)
            localStorage.setItem('permission', JSON.stringify(data.user.Permission))
        }else{
          this.$router.push("/")
        }
    },
  },
  mounted(){
    //this.setupUser()
  },
  watch:{
    userOption(newValue){
      if(newValue == 'logout'){
        this.$router.push('/')
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.info li{
  margin-right: 10px !important;
}
.menu-dropdown li,a{
  color: #000 !important;
  cursor: pointer;
}
.menu-dropdown li:hover{
  background-color: #d2d2d2;
}
</style>
