<template>
    <div class="transaction">
      <Header :mainHeader="true"/>
      <section id="transaction-products">
        <div class="container">
          <h3 style="margin-bottom: 0px !important;">Manage Requests</h3>
          <div class="warehouse-inventory-card-wrapper" style="margin: 5px 0px;">
            <div class="warehouse-inventory-card">
              <p>No. of New P/C Requests for Today</p>
              <strong class="blue">{{ vouchers.length }}</strong>
            </div>
            <div class="warehouse-inventory-card">
              <p>Amount Requested Today</p>
              <strong class="blue">KES {{ getTotal(vouchers) }}</strong>
            </div>
          </div>
  
          <div class="filter-search">
            <div class="filter-search-header" style="margin-bottom: 0px;">
              <strong>Search</strong>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date</label>
                      <select class="full" id="handled-by">
                        <option value="all">Date from - Date to</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">P/C No.</label>
                      <input type="text" class="form-control">
                    </div>
                  </div>
                  
                  <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <label for="handled-by">Project/Dept</label>
                      <select class="full" id="handled-by">
                        <option value="all">All</option>
                      </select>
                    </div>
                  </div>
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="handled-by">Request Status</label>
                      <select class="full" id="handled-by">
                        <option value="all">All</option>
                      </select>
                    </div>
                  </div>
                  
                  <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <label for="handled-by">Request To:</label>
                      <select class="full" id="handled-by">
                        <option value="all">All</option>
                      </select>
                    </div>
                  </div>
                  
                  <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <label for="handled-by">Budget Line</label>
                      <select class="full" id="handled-by">
                        <option value="all">All</option>
                      </select>
                    </div>
                  </div>
                  
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="handled-by">Payment Status</label>
                      <select class="full" id="handled-by">
                        <option value="all">All</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <a href="#" class="btn-style small">Search</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="vouchers-list">
            <table id="vouchersTable" class="table table-hover">
                <thead>
                    <th>No.</th>
                    <th>P/C No.</th>
                    <th>Priority</th>
                    <th>Runtime</th>
                    <th>Request Date</th>
                    <th>R/Progress %</th>
                    <th>Request By</th>
                    <th>Project/Department</th>
                    <th>Budget Line</th>
                    <th>Amount Requested</th>
                    <th>Budget Balance</th>
                    <th>Request Description</th>
                    <th>Action</th>
                </thead>
                <tbody v-if="vouchers.length > 0">
                  <tr v-for="(voucher,index) in vouchers" v-bind:key="voucher.id">
                    <td>{{ (index+1) }}</td>
                    <td>{{ voucher.id }}</td>
                    <td>{{ voucher.priority }}</td>
                    <td>-</td>
                    <td>{{ formatDate(voucher.created_at,true) }}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{{ voucher.budget_line.name }}</td>
                    <td>KES {{ voucher.amount }}</td>
                    <td>-</td>
                    <td>{{ voucher.description }}</td>
                    <td>
                      <div class="btn-group" role="group">
                        <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="dropdown" aria-expanded="false">Action</button>
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-item" @click="viewPC(voucher)">View P/C</a></li>
                            <li><a class="dropdown-item" @click="updatePC(voucher.id, 'approve')">Approve</a></li>
                            <li><a class="dropdown-item" @click="updatePC(voucher.id, 'reject')">Reject</a></li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <div v-else style="position: absolute; left: 0; right: 0; margin-top: 10px;">
                  <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No request found</label>
                </div>
          </table>
          </div>
          
        </div>
      </section>
    </div>


<Modal v-show="isVisible" @close="closeModalSection" >
  <template v-slot:header>
    <h3>Petty Cash Details </h3>
  </template>

  <template v-slot:body>
    <table class="table table-hover">
      <thead><th>Description</th><th>Values</th></thead>
      <tbody>
        <tr><td>P/C No.</td><td>{{ selectedVoucher.id }}</td></tr>
        <tr><td>Project/Dept </td><td> - </td></tr>
        <tr><td>Budget Line </td><td>{{ selectedVoucher.budget_line?.name }}</td></tr>
        <tr><td>Category</td><td>{{ selectedVoucher.voucher_category?.name }}</td></tr>
        <tr><td>Request Description </td><td>{{ selectedVoucher.description }}</td></tr>
        <tr><td>Priority </td><td>{{ selectedVoucher.priority }}</td></tr>
        <tr><td>Request Date </td><td>{{ formatDate(selectedVoucher.created_at, true) }}</td></tr>
        <tr><td>Amount </td><td>KES {{ selectedVoucher.amount }}</td></tr>
        <tr><td>Running Balance </td><td>KES 0.00</td></tr>
        <tr><td>Payment Status </td><td>{{ selectedVoucher.payment_status }}</td></tr>
        <tr><td>Paid By  </td><td>-</td></tr>
        <tr><td>Request by  </td><td>-</td></tr>
        <tr><td>Pay to (Payee)  </td><td>-</td></tr>
        <tr><td>Payment details </td><td>-</td></tr>
        <tr><td>Approval Level 1 </td><td>-</td></tr>
        <tr><td>Action </td><td>-</td></tr>
        <tr><td>Date </td><td>-</td></tr>
        <tr><td>Comment </td><td>-</td></tr>
        <tr><td>Supporting Document </td><td>N/A</td></tr>
        <tr><td>Verification Status </td><td>-</td></tr>
      </tbody>
    </table>
  </template>

  <template v-slot:footer>
    <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="isVisible =false">Close</button>
    <button class="btn btn-default" style="border: 1px solid black; float: right;">View PDF</button>
    <button class="btn btn-danger" style="border: 1px solid black; float: right;" @click="isVisible=false, updatePC(selectedVoucher.id, 'approve')">Reject</button>
    <button class="btn btn-default" style="border: 1px solid black; float: right;">View Attachment</button>
    <button class="btn-style small" style="border: 1px solid black; float: right;" @click="isVisible=false, updatePC(selectedVoucher.id, 'approve')">Approve</button>
  </template>
</Modal>

<Modal v-show="isVisibleApprove" @close="closeModalSectionApprove" >
  <template v-slot:header>
    <h3>Petty Cash Request </h3>
  </template>

  <template v-slot:body>
    <div class="row">
      <div class="form-group" v-if="approveType == 'reject'">
        <label for="date">Select Reason</label>
        <select v-model="cancelId" class="full" id="cancels"  v-if="cancels.length > 0">
            <option v-for="cancel in cancels" v-bind:Key="cancel.id" :value="cancel.id">
                {{ cancel?.name }}
            </option>
        </select>
        <div v-else>
            <h5 for="" style="color: red !important;">No cancellation reason found.</h5> 
        </div>
      </div>

      <div class="form-group">
        <label for="address">Comments</label>
        <textarea v-model="comments" id="description" placeholder="Add comments" class="form-control"></textarea>
      </div>
    </div>
  </template>

  <template v-slot:footer>
    <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="isVisibleApprove =false">Close</button>
    <button class="btn-style small" style="border: 1px solid black; float: right;" v-if="approveType == 'approve'" @click="submitApproval()">Approve</button>
    <button class="btn-style small" style="border: 1px solid black; float: right;" v-else @click="submitApproval()">Reject</button>
  </template>
</Modal>

  </template>
  
  <script>
  import Header from '../../components/Header';
  import Modal from '../../components/Modal.vue';
  import Swal from 'sweetalert2'
  
  export default {
    name: 'ManageRequests',
    components: {
      Header,
      Modal,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        urlServer:process.env.VUE_APP_BACKEND_URL,
        isVisible: false,
        isVisibleApprove: false,
        selectedVoucher: {},
        cancelId:"",
        approveType:"approve",
        voucherId: "",
        comments: "",
        vouchers: [],
        cancels: [],
    }),
    mounted(){
      this.getVouchers()
      this.getCancels()
    },
    methods:{
      viewPC(voucher){
        this.isVisible = true;
        this.selectedVoucher = voucher
      },
      updatePC(voucherId, type){
        this.approveType = type
        this.cancelId = voucherId
        this.isVisibleApprove = true
      },
      closeModalSection(){
        this.isVisible = false;
      },
      closeModalSectionApprove(){
        this.isVisibleApprove = false;
      },
      getTotal(arrayL){
        console.log( {arrayL})
        if(arrayL){
          let total =0;
          arrayL.forEach(element => {
              total = total + parseFloat(element.amount)
          });
          return total;
        } else{
          return 0;
        }
      },
      async getVouchers () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_BACKEND_URL+'/vouchers/', requestOptions)
        const result = await res.json()
        this.vouchers = result.results
      },
      async submitApproval () {
        if(this.comments != ""){
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
                body: JSON.stringify({ 
                  approval_id: this.cancelId,
                  approver: 21,
                  action: this.approveType,
                  comments: this.comments
                })
            };
            const res = await fetch(process.env.VUE_APP_BACKEND_URL+'/stc/approvals/', requestOptions)
            const result = await res.json()
            console.log(result)
            Swal.fire({
                icon: 'success',
                title: 'P/C '+this.approveType+' successfully',
                text: ""
            })
            this.comments = ""
            this.approveType = ""
            this.isVisibleApprove = false
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Missing Field or Data',
                text: "Kindly fill all the fields provided"
            })
        }
      },
      async getCancels () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_BACKEND_URL+'/stc/cancellation-reasons/', requestOptions)
        const result = await res.json()
        this.cancels = result.results
      },
    }
  }
  </script>

  <style scoped>
  .vouchers-list{
    padding: 0px;
    margin-top: 30px;
    /* box-shadow: rgba(49, 32, 104, 0.2) 0 2px 8px 0; */
  }
  </style>