<template>
  <div class="login">

    <Header :loginHeader="true" />

    <section id="login">
      <div class="container text-center">
        <div class="login-wrapper">
          <div class="img-wrapper">
            <img src="../../assets/images/ppt/pavicon_final_logo.png" alt="">
          </div>
          <h3>Pavicon Enterprise Resource Planner</h3>
          <p>Login</p>
          <div class="form">
            <div class="form-group">
              <select class="full" v-model="selectModule" style="background-color: #dfdfdf !important; border-color: #dfdfdf !important;">
                <option value="">Select module</option>
                <option value="Warehouse">Petty Cash Management</option>
              </select>
            </div>
            <div class="form-group">
              <input type="email" placeholder="Email" v-model="email" class="form-control">
            </div>
            <div class="form-group" v-if=!isForgotPassword>
              <input type="password" placeholder="Password" v-model="password" class="form-control">
            </div>
            <div class="form-group">
              <input type="submit" v-if="isForgotPassword == false" value="Login" @click="loginSubmit" class="btn-style full">
              <input type="submit" v-if="isForgotPassword" value="Reset Password" @click="resetSubmit" class="btn-style full">
            </div>
          </div>
          <a href="#" @click="forgotPassword">{{ ForgotPasswordText }}</a>
        </div>
      </div>
    </section>

    <p style="position: absolute;bottom: 0; margin:15px; width: fit-content; ">(C) Copyright 2024 | All Rights Reserved | UbuniWorks</p>
    <p style="position: absolute;bottom: 0; margin:15px; width: fit-content;right: 0; ">Powered by <strong>Ubuni</strong>Works</p>

  </div>
</template>

<script>

import Header from '../../components/Header.vue'
import Swal from 'sweetalert2'

export default {
  name: 'LoginPage',
  components: {
    Header,
  },
  data: () => ({
    LoginText: "Login",
    ForgotPasswordText: "Forgot Password?",
    isForgotPassword : false,
    email: "",
    password: "",
    selectModule:"",
    rules: [
        value => !!value || 'Required.',
        value => (value && value.length >= 4) || 'Min 4 characters',
    ]
  }),
  methods:{
    forgotPassword() {
      this.isForgotPassword = !this.isForgotPassword
      if(this.isForgotPassword) {
        this.LoginText = "Reset Password"
        this.ForgotPasswordText = "Back to Login"
      } else{
        this.LoginText = "Login"
        this.ForgotPasswordText = "Forgot Password?"
      }
    },

    async loginSubmit () {
      if(this.selectModule == ""){
        Swal.fire({
          icon: 'error',
          title: 'Login failed',
          text: 'Select a module'
        })
        return false;
      }
      const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ 
              email: this.email,
              password: this.password
          })
      };
      const res = await fetch(process.env.VUE_APP_BACKEND_URL+'/iam/login', requestOptions)
      const data = await res.json()
      if(data.message == "User logged in successfully."){
          localStorage.setItem('access_token', JSON.stringify(data.token))
          this.$router.push("/home")
      }else{
          Swal.fire({
              icon: 'error',
              title: 'Login failed',
              text: data.message
          })
      }
    },
    async resetSubmit () {
      if(this.email == ""){
        Swal.fire({
          icon: 'error',
          title: 'Password Reset Failed',
          text: 'Enter an email address'
        })
        return false;
      }
      const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ 
              email: this.email
          })
      };
      const res = await fetch(process.env.BACKEND_URL +'/iam/reset', requestOptions)
      const data = await res.json()
      if(data.status == true){
        Swal.fire({
            icon: 'success',
            title: 'Password Reset Successfully',
            text: data.message
        })
      }else{
          Swal.fire({
              icon: 'error',
              title: 'Password Reset Failed',
              text: data.message
          })
      }
    }
  }

}
</script>