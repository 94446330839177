<template>
    <div class="transaction">
      <Header :mainHeader="true"/>
      <section id="transaction-products">
        <div class="container">
          <h3 style="margin-bottom: 0px !important;">Request Logs</h3>
  
          <div class="filter-search">
            <div class="filter-search-header">
              <strong>Search</strong>
            </div>
            <div class="filter-search-body">
              <div class="form">
                <div class="row">
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">Date</label>
                      <select class="full" id="handled-by">
                        <option value="all">Date from - Date to</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-2 align-self-end">
                    <div class="form-group">
                      <label for="date">P/C No.</label>
                      <input type="text" class="form-control">
                    </div>
                  </div>
                  
                  <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <label for="handled-by">Project/Dept</label>
                      <select class="full" id="handled-by">
                        <option value="all">All</option>
                      </select>
                    </div>
                  </div>
                  
                  <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <label for="handled-by">Request Status</label>
                      <select class="full" id="handled-by">
                        <option value="all">All</option>
                      </select>
                    </div>
                  </div>
                  
                  <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <label for="handled-by">Request To:</label>
                      <select class="full" id="handled-by">
                        <option value="all">All</option>
                      </select>
                    </div>
                  </div>
                  
                  <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <label for="handled-by">Budget Line</label>
                      <select class="full" id="handled-by">
                        <option value="all">All</option>
                      </select>
                    </div>
                  </div>
                  
                  <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <label for="handled-by">Payment Status</label>
                      <select class="full" id="handled-by">
                        <option value="all">All</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-1 align-self-end">
                    <div class="form-group">
                      <a href="#" class="btn-style small">Search</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="vouchers-list">
            <table id="vouchersTable" class="table table-hover">
                <thead>
                    <th>No.</th>
                    <th>P/C No.</th>
                    <th>Request Status</th>
                    <th>Request Date</th>
                    <th>A/Runtime</th>
                    <th>R/Progress %</th>
                    <th>Action Date</th>
                    <th>Project/Department</th>
                    <th>Budget Line & Category</th>
                    <th>Amount Requested</th>
                    <th>Payment Status</th>
                    <th>Error</th>
                    <th>Payment Date</th>
                    <th>Action</th>
                </thead>
                <tbody v-if="vouchers.length > 0">
                  <tr v-for="(voucher,index) in vouchers" v-bind:key="voucher.id">
                    <td>{{ (index + 1) }}</td>
                    <td>{{ voucher.id }}</td>
                    <td>{{ voucher.status }}</td>
                    <td>{{ formatDate(voucher.created_at, true) }}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{{ voucher.budget_line?.name }} - {{ voucher.voucher_category?.name }}</td>
                    <td>KES {{ voucher.amount }}</td>
                    <td>{{ voucher.payment_status }}</td>
                    <td>{{ voucher.payment_type }}</td>
                    <td>{{ voucher.payment_date }}</td>
                    <td>
                      <div class="btn-group" role="group">
                        <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="dropdown" aria-expanded="false">Action</button>
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-item" @click="viewPC(voucher)">View P/C</a></li>
                            <li><a class="dropdown-item" href="#">View Payment</a></li>
                            <li><a class="dropdown-item" href="#">View Attachment</a></li>
                            <li><a class="dropdown-item" href="#">Cancel Request</a></li>
                            <li><a class="dropdown-item" href="#">Uplaod Support Doc</a></li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <div v-else style="position: absolute; left: 0; right: 0; margin-top: 10px;">
                  <label for="" class="alert alert-danger" style="margin: 0px auto; width: fit-content; display: block;">No request found</label>
                </div>
          </table>
          </div>
        </div>
      </section>
  
    </div>

<Modal v-show="isVisible" @close="closeModalSection" >
  <template v-slot:header>
    <h3>Petty Cash Details </h3>
  </template>

  <template v-slot:body>
    <table class="table table-hover">
      <thead><th>Description</th><th>Values</th></thead>
      <tbody>
        <tr><td>P/C No.</td><td>{{ selectedVoucher.id }}</td></tr>
        <tr><td>Project/Dept </td><td> - </td></tr>
        <tr><td>Budget Line </td><td>{{ selectedVoucher.budget_line.name }}</td></tr>
        <tr><td>Category</td><td>{{ selectedVoucher.voucher_category.name }}</td></tr>
        <tr><td>Request Description </td><td>{{ selectedVoucher.description }}</td></tr>
        <tr><td>Priority </td><td>{{ selectedVoucher.priority }}</td></tr>
        <tr><td>Request Date </td><td>{{ formatDate(selectedVoucher.created_at, true) }}</td></tr>
        <tr><td>Amount </td><td>KES {{ selectedVoucher.amount }}</td></tr>
        <tr><td>Running Balance </td><td>KES 0.00</td></tr>
        <tr><td>Payment Status </td><td>{{ selectedVoucher.payment_status }}</td></tr>
        <tr><td>Paid By  </td><td>-</td></tr>
        <tr><td>Request by  </td><td>-</td></tr>
        <tr><td>Pay to (Payee)  </td><td>-</td></tr>
        <tr><td>Payment details </td><td>-</td></tr>
        <tr><td>Approval Level 1 </td><td>-</td></tr>
        <tr><td>Action </td><td>-</td></tr>
        <tr><td>Date </td><td>-</td></tr>
        <tr><td>Comment </td><td>-</td></tr>
        <tr><td>Supporting Document </td><td>N/A</td></tr>
        <tr><td>Verification Status </td><td>-</td></tr>
      </tbody>
    </table>
  </template>

  <template v-slot:footer>
    <button class="btn btn-default" style="border: 1px solid black; float: right; margin-right: 10px;" @click="isVisible =false">Close</button>
    <button class="btn-style small" style="border: 1px solid black; float: right;">View Attachment</button>
    <button class="btn-style small" style="border: 1px solid black; float: right;">Print PDF</button>
  </template>
</Modal>

  </template>
  
  <script>
  
import Header from '../../components/Header';
  import Modal from '../../components/Modal.vue'

  export default {
    name: 'RequestLog',
    components: {
      Header,
      Modal,
    },
    data: () => ({
        token: JSON.parse(localStorage.getItem('access_token')),
        isVisible: false,
        selectedVoucher: {},
        vouchers: [],
    }),
    mounted(){
        this.getVouchers()
    },
    methods:{
      viewPC(voucher){
        this.isVisible = true;
        this.selectedVoucher = voucher
      },
      closeModalSection(){
        this.isVisible = false;
      },
      async getVouchers () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_BACKEND_URL+'/vouchers/', requestOptions)
        const result = await res.json()
        this.vouchers = result.results
      },
    }
  }
  </script>

  <style scoped>
  .vouchers-list{
    padding: 0px;
    margin-top: 30px;
    /* box-shadow: rgba(49, 32, 104, 0.2) 0 2px 8px 0; */
  }
  </style>