<template>
    <div class="inventory">
  
      <Header :mainHeader="true"/>
  
      <section id="suppliers-add">
        <div class="container">
          <h3>Add Request</h3>
          <strong>1. Request Details</strong>
          <div>
            <div class="form">
              <div class="row">
                <div class="col-md-2">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Project based</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="projectbased">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-md-3" v-if="projectbased">
                  <div class="form-group">
                      <label for="date">Select Project</label>
                      <select v-model="projectId" class="full" id="projects"  v-if="projects.length > 0">
                          <option value="0">Any</option>
                          <option v-for="project in projects" v-bind:Key="project.id" :value="project.id">
                             {{ project.name }}
                          </option>
                      </select>
                      <div v-else>
                          <h5 for="" style="color: red !important;">No project found.</h5> 
                      </div>
                    </div>
                </div>
                <div class="col-md-3" v-else>
                  <div class="form-group">
                      <label for="date">Select Department</label>
                      <select v-model="departmentId" class="full" id="departments"  v-if="departments.length > 0">
                          <option value="0">Any</option>
                          <option v-for="department in departments" v-bind:Key="department.id" :value="department.id">
                              {{ department.name }}
                          </option>
                      </select>
                      <div v-else>
                          <h5 for="" style="color: red !important;">No department found.</h5> 
                      </div>
                    </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                      <label for="date">Budget</label>
                      <select v-model="budgetId" class="full" id="budgets"  v-if="budgets.length > 0">
                          <option value="0">Any</option>
                          <option v-for="budget in budgets" v-bind:Key="budget.id" :value="budget.id">
                              {{ budget.name }}
                          </option>
                      </select>
                      <div v-else>
                          <h5 for="" style="color: red !important;">No budget line found.</h5> 
                      </div>
                    </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                      <label for="date">Category</label>
                      <select v-model="categoryId" class="full" id="categories"  v-if="categories.length > 0">
                          <option value="0">Any</option>
                          <option v-for="category in categories" v-bind:Key="category.id" :value="category.id">
                              {{ category.name }}
                          </option>
                      </select>
                      <div v-else>
                          <h5 for="" style="color: red !important;">No category found.</h5> 
                      </div>
                    </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="item-code">Requested Amount</label>
                    <input type="number" min="0" class="form-control" placeholder="Enter amount" v-model="amount">
                  </div>
                </div>
                <div class="col-md-1">
                  <div class="form-group">
                    <label for="address">Priority</label>
                    <select name="priority" v-model="priority" class="form-control">
                      <option value="LOW">Low</option>
                      <option value="MEDIUM">Medium</option>
                      <option value="HIGH">High</option>
                      <option value="CRITICAL">Critical</option>
                    </select>
                  </div>
                </div>  
                <div class="col-md-8">
                  <div class="form-group">
                    <label for="address">Request Description</label>
                    <input type="text" v-model="description" id="description" class="form-control">
                  </div>
                </div>  
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="address">Approval Levels</label>
                    <input type="text" v-model="levels" readonly placeholder="Loading ..." class="form-control">
                  </div>
                </div>  
                
                <div class="col-md-2">
                    <div class="check-button">
                    <div class="form-group">
                        <label>Pay to</label>
                        <div class="components-button">
                    <span class="switcher switcher-1">
                        <input type="checkbox" id="switcher-1" v-model="payTo">
                        <label for="switcher-1"></label>
                    </span>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group">
                    <label for="address">Payee Name</label>
                    <input type="text" id="description" placeholder="Enter name of person or business receiving payment" class="form-control">
                  </div>
                </div>  
                <div class="col-md-5">
                  <div class="form-group">
                    <label for="address">Payment Details (Paybill/Till No./Bank Details)</label>
                    <input type="text" id="description" placeholder="Enter payment details" class="form-control">
                  </div>
                </div>  
              </div>
  
            </div>
            
            <h4>Terms & Conditions</h4>
            <div style="background-color: #f2f2f2;border-radius: 5px; padding: 10px;">
              <p style="margin-bottom: 2px;">1. To make payments to individuals (eg. payments to visiting lecturers to defray expenses).</p>
              <p style="margin-bottom: 2px;">2. To make payments to external suppliers for services rendered.</p>
              <p style="margin-bottom: 2px;">3. For the regular purchase of items that could be obtained through iProcurement (eg. the monthly replenishment of consumables such as tea and coffee, cleaning materials or light
                  bulbs).</p>
              <p style="margin-bottom: 2px;">4. Supporting document must be uploaded by 14 days, failure to comply will see your account terminated. </p>
            </div>
          </div>
        </div>
      </section>
      
      <div>
        <button class="btn-style small" style="float: right; margin-right: 15px;" @click="postRequest">Request</button>
        <button class="btn btn-default" style="float: right; margin-right: 15px; border: 1px solid #000;" @click="this.$router.push('/requests')">Cancel</button>
      </div>
  
    </div>
  </template>
  
  <script>
  
  import Swal from 'sweetalert2'
  import Header from '../../components/Header'
  
  export default {
    name: 'AddRequest',
    components: {
      Header,
    },
    data: () => ({
      token: JSON.parse(localStorage.getItem('access_token')),
      urlServer:process.env.VUE_APP_BACKEND_URL,
      name: "",
      description: "",
      priority: "LOW",
      contact: "",
      amount: "",
      projectbased: true,
      payTo: false,
      projectId:"",
      budgetId:2,
      categoryId:1,
      budgets:[],
      categories:[],
      departments:[],
      projects:[]
    }),
    mounted(){
      this.getBudgets()
      this.getDepartments()
      this.getCategories()
      this.getProjects()
    },
    methods:{
        async postRequest () {
          let basis = "PROJECT"
          if(!this.projectbased){
            basis = "DEPARTMENT"
          }
          const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
              body: JSON.stringify({ 
                  name: "Web Test",
                  basis: basis,
                  description: this.description,
                  priority: this.priority,
                  amount: this.amount,
                  requested_by: 2,
                  budget_line: this.budgetId,
                  voucher_category: this.categoryId
              })
          };
          const res = await fetch(this.urlServer+'/vouchers/', requestOptions)
          const data = await res.json()
          if(data.status == true){
              Swal.fire({
                  icon: 'success',
                  title: 'Request created successfully',
                  text: data.message
              })
          }else{
              Swal.fire({
                  icon: 'error',
                  title: 'Request not created',
                  text: data.message
              })
          }
        },
      async getBudgets () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_BACKEND_URL+'/stc/budgets/', requestOptions)
        const result = await res.json()
        this.budgets = result.results
      },
      async getDepartments () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_BACKEND_URL+'/stc/departments/', requestOptions)
        const result = await res.json()
        this.departments = result.results
      },
      async getCategories () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_BACKEND_URL+'/stc/categories/', requestOptions)
        const result = await res.json()
        this.categories = result.results
      },
      async getProjects () {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer '+this.token },
        };
        const res = await fetch(process.env.VUE_APP_BACKEND_URL+'/stc/projects/', requestOptions)
        const result = await res.json()
        this.projects = result.results
      },
        
    }
  }
  </script>

  <style lang="scss" scoped>

$primary: #d2d2d2;
$green: #312068;
$white: #ffffff;
  .check-button{
        display: flex;
        align-items: center;
        gap: 10px;
      }
      .form-group{
        .components-button {
          display: flex;
          align-items: center;
          justify-content: left;
          span.switcher {
            position: relative;
            width: 156px;
            height: 56px;
            border-radius: 10px;
            input {
              appearance: none;
              position: relative;
              width: 130px;
              height: 40px;
              border-radius: 3px;
              border-color: #d2d2d2 !important;
              background: $primary;
              outline: none;
              font-family: 'Oswald', sans-serif;
              &:before, &:after {
                z-index: 2;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: $white;
              }
              &:before {
                content: 'Yes';
                left: 16px;
                font-weight: 600;
                font-size: 16px;
                line-height: 33px;
                color: #FFFFFF;
              }
              &:after {
                content: 'No';
                right: 16px;
                font-weight: 600;
                font-size: 17px;
                line-height: 33px;
                color: #FFFFFF;
              }
            }
            label {
              z-index: 1;
              position: absolute;
              width: 55px;
              height: 32px;
              margin: 0;
              top: 4px;
              left: 71px !important;
              border-radius: 3px;
            }
            &.switcher-1, &.switcher-2 {
              input {
                transition: .25s -.1s;
                &:checked {
                  background: $primary;
                  &:before {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  &:after {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  & + label {
                    left: 4px !important;
                    background: $green;
                    transition: left .5s, right .4s .2s;
                  }
                }
                &:not(:checked) {
                  background: $primary;
                  transition: background .5s -.1s;
                  &:before {
                    color: #ffffff;
                    transition: color .5s;
                  }
                  &:after {
                    color: $white;
                    transition: color .5s .2s;
                  }
                  & + label {
                    left: 100px;
                    right: 10px;
                    background: $green;
                    transition: left .4s .2s, right .5s, background .35s -.1s;
                  }
                }
              }
            }
          }
        }
      }
  </style>